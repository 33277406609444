function _onload() {

  var myWindow = null

  function ShowImage(src, width, height) {
    if (myWindow != null) myWindow.close()
    var widthB = width + 20
    var heightB = height + 20
    if (height >= 768 || width >= 1024)
      myWindow = window.open("", "_blank", "status=no,toolbar=no,location=no,scrollbars=yes,width=" + widthB +
        ",height=" + heightB);
    else
      myWindow = window.open("", "_blank", "status=no,toolbar=no,location=no,width=" + widthB + ",height=" + heightB);
    myWindow.document.write('<IMG onclick="window.close()" src=' + src + ' width=' + width + ' height=' + height +
      '></IMG>');
  }

  window.ShowImage = ShowImage;

}

window.addEventListener('load', _onload);